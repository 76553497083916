import React, { useEffect, useState } from "react";
import {
  Container, Col, Row, Modal, ModalBody, Button 
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { withTranslation } from "react-i18next";
import "./Certificates.scss";

import verify from "../../../src/assets/img/verify.png";
// import Certification from "../../../src/assets/img/Certificate for Product Day 2024.png";

import { useSelector } from "react-redux";

const CertificateCard = ({ image, title, content, onClick, layoutMode }) => {
  // const [numPages, setNumPages] = useState(null);
  return (  
  <Col lg="6">
    <div className={`${layoutMode === "light" ? "card_certificate2" : "card_certificate"}  p-4 pt-3 my-1`} onClick={onClick}>
      <div className="d-flex">
        <div>
          <img src={verify} alt="Verify" />
        </div>
        <h6  className={`${layoutMode === "light" ? "dark" : "white"}  mx-4 mt-1`}>{title}</h6>
      </div>
      <div className="mx-5 mt-1">
        {/* {image && <img src={image} alt="Certificate" />} */}
      </div>
      <p className="color_certification ms-5 mt-1">{content}</p>
    </div>
  </Col>
  );
};
  

function Certificates({ t }) {
    const {
        layoutMode,
      } = useSelector(state => ({
        layoutMode: state.Layout.layoutMode,
      }));
    const {
        clientData,
      } = useSelector(state => ({
        clientData: state?.Profile?.clientData,
      }));
      console.log("Layout Mode: " + layoutMode);
//   State   

  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [certificate, setCertificate] = useState(null);
  // const [numPages, setNumPages] = useState(null);
  //   Handle Model 
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const openModal = (certificate) => {
    setCertificate(certificate);
    setModalImage(certificate?.link);
    toggleModal();
  };
// Handle Download Image Upload 
  const downloadImage = () => {
    // const link = document.createElement("a");
    // link.href = modalImage;
    // link.setAttribute("download", "certificate");
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
    window.open(modalImage, "_blank");
  };
  return (
    <div className="page-content mt-5 my-5">
      <MetaTags>
        <title>{t("Certificates")}</title>
      </MetaTags>

      <Container>
      <h2 className="text-body my-3">Certificates</h2>
        <div className={`${layoutMode === "light" ? "background_color2" : "background_color"} p-3`}>
          <Row>
            {
              clientData?.certificates && clientData?.certificates?.map((certificate, index)=>(
                <CertificateCard
                  key={index}
                  image={certificate?.link}
                  title={certificate?.challenge_title}
                  onClick={() => openModal(certificate)}
                  layoutMode={layoutMode}
                />
              ))
            }
          </Row>
        </div>
      </Container>

      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        className="model_style "
        style={{
          minWidth: "740px",
        }}
      >
        <div className="align-items-end d-flex justify-content-end gap-3 me-4 mt-3">
        <Button className="bg-transparent text-body" onClick={downloadImage}>Download</Button>
        <Button className="bg-red text-white" onClick={toggleModal}>Close</Button>
        </div>
        <ModalBody className="text-center">
          {certificate?.challenge_title && <h1>Challenge: {certificate?.challenge_title}</h1> }
          {certificate?.plan_title && <h2>Plan: {certificate?.plan_title}</h2> }
          
          {/* {modalImage && (
            <img
              src={modalImage}
              alt="Certificate"
              style={{
                minWidth: "700px",
                height: "500px",
              }}
            />
          )} */}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default withTranslation()(Certificates);
